.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Spinner */
@keyframes ldio-rpinwye8j0b {
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
}

@keyframes spin {
  from{transform: rotate(0deg);}
  to{transform: rotate(360deg);}
}

.loader {
  border: 2px solid #FFFFFF; 
  border-top-color: #14B48C; 
  width: 40px; 
  height: 40px; 
  border-radius: 50%; 
  /* animation: spin 1s infinite; */
  animation: ldio-rpinwye8j0b 1s linear infinite;;
}

.loader1 {
  border: 7px solid #FFFFFF; 
  border-top-color: #14B48C; 
  width: 70px; 
  height: 70px; 
  border-radius: 50%; 
  /* animation: spin 1s infinite; */
  animation: ldio-rpinwye8j0b 1s linear infinite;
}

.overlay-effect {
  position: absolute;
  border-radius: 10px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  background-color: #050000;
  overflow: hidden;
  z-index: 1;
}

.logo {
  width: 6rem;
}

.border {
  border-radius: 30px;
}

.remove-button:hover {
  border-radius: 10px;
  
}

.just-border {
  border-color: rgb(16, 100, 55);
  background-color: rgb(168, 251, 207);
}

.button-style {
  border-color: #106437;
  background-color: #FFFFFF;
}

.button-style:hover {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.color-border-active {
  border-color: #106437;
}
.color-border-active:hover {
  border-color: #FFFFFF;
}

.color-border-inactive {
  border-color: #D1D1D1;
}
.color-border-inactive:hover {
  border-color: #FFFFFF;
}

.color-border-deactivated {
  border-color: #f80202;
}
.color-border-deactivated:hover {
  border-color: #FFFFFF;
}

.elevation {
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.3), 0 0 5px rgba(157, 158, 157, 0.3);
  -webkit-box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.3), 0 0 5px rgba(157, 158, 157, 0.3);
}

.form-control-custom:focus {
  cursor: pointer;
  border-color: #106437;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 5px rgba(16, 100, 55, 0.6); 
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 5px rgba(16, 100, 55, 0.6);
}

.form-control:focus {
  border-color: #106437;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 5px rgba(16, 100, 55, 0.6); 
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 5px rgba(16, 100, 55, 0.6);
}

.btn-control:focus {
  border-color: #106437;
  -webkit-box-shadow: none; 
  box-shadow: none;
}

/*  */
/* .bootstrap-iso .formden_header h2, .bootstrap-iso .formden_header p, .bootstrap-iso form{
  font-family: Arial, Helvetica, sans-serif; 
  color: black
}

.bootstrap-iso form button, .bootstrap-iso form button:hover{
  color: white !important;
}

.bootstrap-iso .form-control:focus {
  border-color: #106437;  
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(16, 100, 55, 0.6); 
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(16, 100, 55, 0.6);
} 

.asteriskField{
  color: red;
} */
/*  */

/* Hide image on mobile view */
#mobile_only {
  display: none;
}

@media screen and (min-width: 768px) {
  #mobile_only {
    display: block;
    clear: both;
    /* float: left; */
    /* margin: 10px auto 5px 20px; */
    /* width: 28%; */
  }
}

/* Custom checkbox */
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #106437!important;
}

.custom-checkbox .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 255, 0, 0.25)
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25)
}
.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  background-color: #C8FFC8; 
}
/* Custom checkbox - end */

/* Custom radio */
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #106437!important;
}

.custom-radio .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 255, 0, 0.25)
}
.custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25)
}
.custom-radio .custom-control-input:active ~ .custom-control-label::before {
  background-color: #C8FFC8; 
}
/* Custom radio - end */

.button-hover:hover {
  background-color: #050000;
}

.txt-dark-grey {
  color: #757575;
}

.txt-light-red {
  color: #fdbdbd;
}

.txt-color-light {
  color: #D1D1D1;
}

.txt-color {
  color: #106437;
}

.txt-color:hover {
  color: #8fb4a1;
}

.btn-empty-light {
  background-color: #f8f9fa;
}

.btn-color-dark {
  background-color: #aeafaf;
}

.btn-color-dark:hover {
  background-color: #959696;
}

.btn-color {
  background-color: #106437;
}

.btn-color-light {
  background-color: #FFFFFF;
  border-color: #106437;
}

.btn-color:hover {
  background-color: #1c4730;
}

.scale-transition:hover {
  /* background-color: #CFF4D2; */
  border-radius: 10px;
  transform: scale(1.1);
  transition: transform 330ms ease-in-out;
}

.scale-transition {
  border-radius: 10px;
  transition: transform 530ms ease-in-out;
}

.menu-text:hover {
  text-size-adjust: 80%;
}

.hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

.profileDropdown {
  z-index: 3;
  background: #DDD;
  border-radius: 5px;
  /* margin-left: auto; */
  /* margin-top: 5px; */
  /* padding: 2px; */
  position: relative;
  width: 200px;
  /* height: 140px; */
}

.filterBy {
  background: #fff;
  border-radius: 5px;
  height: 38px;
  right: 0;
  margin-top: 2rem;
  width: 300px;
}

.filterByDropdown {
  z-index: 3;
  background: #FFFFFF;
  border-radius: 5px;
  /* margin-left: auto; */
  /* margin-top: 5px; */
  /* padding: 2px; */
  position: relative;
  width: 300px;
  /* height: 140px; */
}

.selectCurrencyButton {
  background: #fff;
  border-radius: 5px;
  height: 38px;
  left: 0;
  margin-top: 3rem;
  padding: 2px;
  width: 220px;
}

.selectCurrencyDropdown {
  z-index: 1;
  background: #f9f9f9;
  border-radius: 5px;
  /* margin-left: auto; */
  /* margin-top: 5px; */
  /* padding: 2px; */
  position: relative;
  width: 216px;
  /* height: 140px; */
}

.filterStatusButton {
  background: #fff;
  border-radius: 5px;
  height: 38px;
  left: 0;
  margin-top: 2rem;
  padding: 2px;
  width: 220px;
}

.filterStatusDropdown {
  background: #f9f9f9;
  border-radius: 5px;
  /* margin-left: auto; */
  /* margin-top: 5px; */
  /* padding: 2px; */
  position: relative;
  width: 216px;
  /* height: 140px; */
}

.network {
  background: #DDD;
  border-radius: 5px;
  /* height: 38px; */
  left: 0;
  /* margin-top: 2rem; */
  padding: 2px;
  width: 220px;
}

.react-select-container {
  min-height: '3rem';
  border-color: #106437;
  border-radius: '10px';
}

.css-1s2u09g-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 3rem;
  outline: 0!important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.rmsc.dark-style {
  --rmsc-main: #106437;
  --rmsc-hover: #0e0c0a;
  --rmsc-selected: #1d1915;
  --rmsc-border: #333333;
  --rmsc-gray: #555555;
  --rmsc-bg: #000000;
  color: #fff;
  /* color: #fff; */
}

.rmsc {
  --rmsc-main: #106437 !important;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 10px !important; /* Radius */
  --rmsc-h: 3rem !important; /* Height */
}

.networkDropdown {
  z-index: 3;
  background: #FFFFFF;
  border-radius: 5px;
  position: relative;
  width: auto;
}

.bg-login {
  position: relative;
  background: url('./assets/login.png') no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  /* margin-top: 24px; */
  /* margin-bottom: -50px; */
}

.bg-purchase {
  position: relative;
  background: url('./assets/purchase_sim.png') no-repeat;
  background-size: cover;
  background-position: center;
  height: 50vh;
}

/* Floating Label Input */
#float-label {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  position: relative;
}

#float-label input {
  width: 100%;
  height: 60px;
  padding: 14px 16px 0 10px;
  outline: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

#float-label label {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0 12px;
  color: #999;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 26px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
}

#float-label:focus-within label {
  transform: translate(0, 8px) scale(0.75);
}

#float-label .Active {
  transform: translate(0, 12px) scale(0.75);
}

/* Snackbar */
#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: rgb(48, 47, 47);
  color: #c6e6d5;
  text-align: center;
  border-radius: 10px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 1s 3s;
  animation: fadein 0.5s, fadeout 1s 3s;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}